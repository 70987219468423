import { render, staticRenderFns } from "./Osa013.vue?vue&type=template&id=fe9a773c&scoped=true"
var script = {}
import style0 from "./Osa013.vue?vue&type=style&index=0&id=fe9a773c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe9a773c",
  null
  
)

export default component.exports