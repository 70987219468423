<template>
  <div id="saannot">
    <section id="parallax-1" class="hero is-large">
        <div class="hero-body">
            <div class="container">
              <h1>Jaanalla on asiaa OSA 1</h1><br>
              <div style="position: relative; padding-top: 56.25%;"><iframe src="https://iframe.videodelivery.net/e3828c030c4a15cb234dc5a4aaf001b4?poster=https%3A%2F%2Fvideodelivery.net%2Fe3828c030c4a15cb234dc5a4aaf001b4%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D12m22s%26height%3D600" style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe></div>
            </div>
            <br>
            <router-link to="/jaanalla-on-asiaa/">&ltri; Takaisin</router-link>
          </div>
    </section>
  </div>
</template>
<script>
  export default {
    name: 'App',
    metaInfo: {
      title: 'Jaanalla on asiaa - OSA 1',
    }
  }
</script>
<style scoped>
p {
  margin-top: 1rem;
}
h1 {
    margin-top: 1rem;
    font-size: 1.8rem;
    font-weight: bold;
}
h2 {
    margin-top: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
}
h3 {
    margin-top: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
}
#parallax-1 {

  background: url(../../assets/1_elegant_BW_spotlight_background.jpg);

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


</style>